import React,{useState} from 'react';
import { StyleSheet, Text, View,Image,useWindowDimensions,ScrollView } from 'react-native';

import CustomInput from '../../components/CustomInput';
import CustomButton from '../../components/CustomButton';


const onSignInPressed=()=>{
    alert('Hello');
}

const onForgotPassword=()=>{
    console.warn('Forgot password not applicable contact System Administrator');
}


const ScrLogin = () =>{
    const {height,width} = useWindowDimensions();
    const [username , setUserName] = useState('');
    const [userpassword , setUserPassword] = useState('');

    return (
       
        <View showsVerticalScrollIndicator={false}>
            <Image source={require('../../assets/logo/com_logo1.png')} style={ [styles.logo, {height : height*0.25 , width : width*0.40}]} resizeMode='contain' />
            <ScrollView>
                <CustomInput  placeholder="User Name" value={username} setValue={setUserName} iconType={'user'}/>
                <CustomInput  placeholder="Password"  value={userpassword} setValue={setUserPassword} iconType={'key'} secureTextEntry={true} />
                <CustomButton text="LOGIN" onPress={onSignInPressed}/>
                <CustomButton text="Forgot password ?" onPress={onForgotPassword} type='TERTIARY'/>
            </ScrollView>
        </View>
        
    )
}
const styles = StyleSheet.create({
    logo:{
        marginTop:'-25%',
        marginVertical:'5%',
        marginHorizontal:50
    }
});

export default ScrLogin;