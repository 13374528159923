import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import React from 'react';

import { useFonts } from 'expo-font';
import HRAPP from './core/hr.middleton.in';


export default function App() {

  const [loaded]=useFonts({
    'GeForceLight': require('./core/assets/fonts/GeForce-Light.ttf'),
    'GeForceBold': require('./core/assets/fonts/GeForce-Bold.ttf'),
    'GeForceLightAlt': require('./core/assets/fonts/GeForce-Light-Alt.ttf'),
    'GeForceBoldAlt': require('./core/assets/fonts/GeForce-Bold-Alt.ttf')
  });

  if(!loaded){
    return null;
  }

  return (
      <HRAPP/>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
