import React from 'react';
import { StyleSheet, TextInput, View} from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';


const CustomInput = ({value,setValue,placeholder,secureTextEntry,iconType}) =>{
    return (
        <View style={styles.container}>

            <View style={styles.iconStyle}>
                <AntDesign name={iconType} size={25} color="#666" />
            </View>

            <TextInput style={styles.input} 
            placeholder={placeholder} 
            value={value} 
            onChangeText={setValue} 
            secureTextEntry={secureTextEntry}
            />
        </View>
    )
}
const styles = StyleSheet.create({
    container : {
        marginTop: 5,
        marginBottom: 10,
        width: '100%',        
        height: 45,
        borderColor: '#ccc',
        borderRadius: 3,
        borderWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff',
    },
    input:{
        padding: 10,
        flex: 1,
        fontSize: 19,
        fontFamily: 'GeForceBoldAlt',
        letterSpacing:1,
        color: '#333',
        justifyContent: 'center',
        alignItems: 'center'
        
    },
    iconStyle: {
        padding: 10,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        borderRightColor: '#ccc',
        borderRightWidth: 1,
        width: 50
      }
});

export default CustomInput;