import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import ScrLogin from './screens/public/_login'


export default function HRAPP() {
    return (
      <View style={styles.container}>
        <ScrLogin></ScrLogin>
      </View>
    );
  }


const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',      
      fontFamily:'GeForceLight',
      color: '#FAE042',
      fontSize: 18,
      marginTop: 8
    }
  });